import styled from 'styled-components';

export const Container = styled.div`
  background-color: rgba(216, 216, 216, 0.25);
  flex-direction: column;
  border-radius: 4px;
  padding: 12px;
  align-items: center;
  margin-bottom: 14px;

  @media ${({ theme: { breakpoints } }): string => breakpoints.desktop} {
    padding: 30px;
  }
`;

export const Text = styled.span`
  font-family: ${({ theme: { fonts } }): string => fonts.main};
  font-size: 13px;
  color: ${({ theme: { colors } }): string => colors.lightGrey};
  margin-bottom: 11px;
  text-align: center;
`;

export const ButtonContainer = styled.div`
  margin: 20px;
`;

export const Span = styled.text`
  text-align: center;
  color: #fff;
  font-size: 12px;
`;

export const BottomRightRibbon = styled.div`
  width: 140px;
  background: #e43;
  left: auto;
  text-align: center;
  letter-spacing: 1px;
  color: #f0f0f0;
  transform: rotate(-35deg);
  position: absolute;
  top: auto;
  right: auto;
  bottom: auto;
`;
