import React, { FC, useCallback } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { navigate } from "gatsby";

import {
  Container,
  Text,
  ButtonContainer,
} from "./style/personalize-shopping-cart";
import ButtonRound, { BUTTON_TYPE } from "../../ui/button-round";
import ShoppingCartBlueIcon from "../../images/shopping-cart-blue.svg";

const PersonalizeShoppingCart: FC = () => {
  const intl = useIntl();
  const goToProducts = useCallback(() => {
    navigate("/pricing");
  }, [navigate]);

  return (
    <Container>
      <Text>
        <FormattedMessage id="cart.order.personalizeOrFinishOrder" />
      </Text>
      <ButtonContainer>
        <ButtonRound
          onClick={goToProducts}
          type={BUTTON_TYPE.SECONDARY}
          left={<ShoppingCartBlueIcon />}
        >
          {intl.formatMessage({ id: "cart.order.personalizeCart" })}
        </ButtonRound>
      </ButtonContainer>

    </Container>
  );
};

export default PersonalizeShoppingCart;
