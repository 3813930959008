import { useEffect, useState } from "react";

import { getOptions } from "../../store/shopping-cart/actions";
import { useToast, TOAST_TYPE } from "../../ui/toast";
import useThunkDispatch from "../../hooks/use-thunk-dispatch";

interface UseOptionsResult {
  loading: boolean;
}

const useOptions = (): UseOptionsResult => {
  const dispatch = useThunkDispatch();
  const { showToast } = useToast();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async (): Promise<void> => {
      setLoading(true);

      const response = await dispatch(getOptions());
      if (!response.ok) {
        showToast(response.error ? response.error.message : "Error", {
          type: TOAST_TYPE.ERROR,
        });
      }

      setLoading(false);
    };

    fetchData();
  }, [dispatch, showToast]);

  return { loading };
};

export default useOptions;
