import styled from 'styled-components';

export const Container = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  background-color: ${({ theme: { colors } }): string => colors.grey};

  @media ${({ theme: { breakpoints } }): string => breakpoints.desktop} {
    bottom:0;
  }

`;

export const FirstBlock = styled.div`
  @media ${({ theme: { breakpoints } }): string => breakpoints.desktop} {
    width: 60%;
  }
`;

export const SecondBlock = styled.div`
  @media ${({ theme: { breakpoints } }): string => breakpoints.desktop} {
    width: 40%;
    margin-left: 40px;
  }
`;

export const ContentContainer = styled.div`
  padding: 15px;
  width: 90%;

  @media (min-width: 700px) {
    width: 50%;
  }

  @media ${({ theme: { breakpoints } }): string => breakpoints.desktop} {
    width: 70%;
  }
`;

export const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 20px 0;
  height: 40px;
`;

export const OptionsContainer = styled.div`
  margin-bottom: 24px;
`;

export const PickupDropOffContainer = styled.div`
  margin-bottom: 24px;
`;

export const Title = styled.span`
  margin-bottom: 10px;
  font-family: ${({ theme: { fonts } }): string => fonts.main};
  font-weight: bold;
  font-size: 14px;
  color: ${({ theme: { colors } }): string => colors.primary};
`;

interface SpacerProps {
  height: number;
}

export const Spacer = styled.div<SpacerProps>`
  height: ${({ height }): number => height}px;
`;

export const PageTitle = styled.span`
  margin-bottom: 24px;
  margin-top: 50px;
  font-family: ${({ theme: { fonts } }): string => fonts.main};
  font-weight: bold;
  font-size: 20px;
  color: ${({ theme: { colors } }): string => colors.primary};
  display: flex;
  justify-content: center;
`

export const CartBlock = styled.div`
  display:flex;
  flex-direction: column;
  margin-bottom: 70px;
  margin-top: 50px;

  @media ${({ theme: { breakpoints } }): string => breakpoints.desktop} {
    flex-direction: row;
  }
`
