import React, { FC } from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";
import Cart from "../components/shopping-cart";

const IndexPage: FC = () => (
  <Layout
    header={false}
    footer={false}
    menuState="cart"
    progressbar={true}
    progressBarStep={1}
  >
    <SEO title="Panier" />
    <Cart />
  </Layout>
);

export default IndexPage
