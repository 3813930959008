import styled from 'styled-components';
import { Theme } from '../../../theme/theme-provider';

export enum BUTTON_TYPE {
  PRIMARY = 'PRIMARY',
  SECONDARY = 'SECONDARY',
  UNDERLINE = 'UNDERLINE',
}

interface Props {
  theme: Theme;
  type?: BUTTON_TYPE;
}

const getColor = ({ theme: { colors }, type }: Props): string | undefined => {
  switch (type) {
    case BUTTON_TYPE.PRIMARY:
      return colors.primary;
    case BUTTON_TYPE.SECONDARY:
      return colors.secondary;
    case BUTTON_TYPE.UNDERLINE:
      return colors.lightGrey;
  }
};

export const Container = styled.div<Props>`
  padding: 10px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border: none;
`;

export const Loader = styled.div`
  width: 10px;
  height: 10px;
`;

const IconContainer = styled.div`
  width: 24px;
  height: 24px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

export const Left = styled(IconContainer)`
  margin-right: 5px;
`;

export const Right = styled(IconContainer)`
  margin-left: 5px;
`;

export const Text = styled.span<Props>`
  font-family: ${({ theme: { fonts } }): string => fonts.main};
  font-size: 14px;
  color: ${getColor};
  text-decoration-line: ${({ type }): string => (type === BUTTON_TYPE.UNDERLINE ? 'underline' : 'none')};
`;
