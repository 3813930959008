import React, { FC, useEffect } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import dayjs from 'dayjs';

import {
  Container,
  ContentContainer,
  Title,
  PageTitle,
  ButtonContainer,
  CartBlock,
  FirstBlock,
  SecondBlock,
  OptionsContainer,
  PickupDropOffContainer,
} from './style/shopping-cart-page';
import Options from './options';
import ButtonLink from '../../ui/button-link';
import AddressButton from './address-button';
import OrderSummary from './order-summary';
import DiscountCode from '../discount-code';
import ProductsInCart from './products-in-cart';
import PersonalizeShoppingCart from './personalize-shopping-cart';
import PickupDropOffRangePicker from '../pickup-drop-off-range-picker';
import { AppState } from '../../store';
import useThunkDispatch from '../../hooks/use-thunk-dispatch';
import { resetTimeslot } from '../../store/shopping-cart/actions';
import { navigate } from 'gatsby';
import { BackBtnIcon } from '../profile-back-btn-icon';

const ShoppingCartScreen: FC = () => {
  const intl = useIntl();
  const dispatch = useThunkDispatch();
  const token = useSelector<AppState, string>(state => state.auth.token);
  const pickupDate = useSelector<AppState, string>(state =>
    state.shoppingCart.pickupRange
      ? state.shoppingCart.pickupRange.startDate.toString()
      : ''
  );
  const dropoffDate = useSelector<AppState, string>(state =>
    state.shoppingCart.dropOffRange
      ? state.shoppingCart.dropOffRange.startDate.toString()
      : ''
  );

  useEffect(() => {
    if (pickupDate || dropoffDate) {
      if (
        dayjs(pickupDate).isBefore(dayjs().format('YYYY-MM-DD HH:mm:ss')) ||
        dayjs(dropoffDate).isBefore(dayjs().format('YYYY-MM-DD HH:mm:ss'))
      )
        dispatch(resetTimeslot());
    }
  });

  return (
    <>
      <Container>
        <ContentContainer>
          <PageTitle>
            <BackBtnIcon />
            <FormattedMessage id="cart.title" />
          </PageTitle>
          <CartBlock>
            <FirstBlock>
              <Title>
                <FormattedMessage id="cart.address.title" />
              </Title>
              <AddressButton />
              <Title>
                <FormattedMessage id="cart.options.title" />
              </Title>
              <OptionsContainer>
                <Options />
              </OptionsContainer>
              <Title>
                <FormattedMessage id="cart.shippingTime.title" />
              </Title>
              <PickupDropOffContainer>
                <PickupDropOffRangePicker />
              </PickupDropOffContainer>
            </FirstBlock>
            <SecondBlock>
              <Title>
                <FormattedMessage id="cart.order.title" />
              </Title>
              <ProductsInCart />
              <PersonalizeShoppingCart />
              <DiscountCode />
              <OrderSummary />
            </SecondBlock>
          </CartBlock>
          <ButtonContainer>
            <ButtonLink
              link="#"
              text={intl.formatMessage({ id: 'cart.finaliseOrder' })}
              onClick={(): Promise<void> => navigate(token ? '/order' : '/signin-from-cart')}
            ></ButtonLink>
          </ButtonContainer>
        </ContentContainer>
      </Container>
    </>
  );
};

export default ShoppingCartScreen;
