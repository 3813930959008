import styled from 'styled-components';

export const OptionDescriptionCheck = styled.div`
  background-color: ${({ theme: { colors } }): string => colors.secondary};
  border-radius: 10px;
  height: 20px;
  width: 20px;
  padding-left: 2px;
  margin-top: 2.5px;
  margin-left: 2.5px;
`;

export const OptionDescriptionView = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

export const OptionDescriptionItem = styled.div`
  background-color: ${({ theme: { colors } }): string => colors.white};
  border-radius: 12.5px;
  display: flex;
  flex-direction: row;
  height: 25px;
  margin-right: 20px;
  margin-top: 10px;
`;

export const OptionDescriptionText = styled.span`
  font-size: 11px;
  margin: 0 6px;
  font-family: ${({ theme: { fonts } }): string => fonts.main };
`;
