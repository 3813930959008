import React, { FC } from "react";
import { FormattedMessage } from "react-intl";

import CheckIcon from "../../images/Check.svg";
import {
  OptionDescriptionCheck,
  OptionDescriptionItem,
  OptionDescriptionText,
  OptionDescriptionView,
} from "./style/option-description";

interface OptionDescriptionProps {
  pickupLeadTime: number;
  price: string;
  timeslotDuration: number;
}

const OptionDescription: FC<OptionDescriptionProps> = ({
  timeslotDuration,
  pickupLeadTime,
  price,
}) => {
  const priceIsZero = parseFloat(price) === 0 ?? false;

  return (
    <OptionDescriptionView>
      <OptionDescriptionItem>
        <OptionDescriptionCheck>
          <CheckIcon />
        </OptionDescriptionCheck>
        <OptionDescriptionText>
          <FormattedMessage
            id="cart.options.timeslotDuration"
            values={{ timeslotDuration }}
          />
        </OptionDescriptionText>
      </OptionDescriptionItem>
      <OptionDescriptionItem>
        <OptionDescriptionCheck>
          <CheckIcon />
        </OptionDescriptionCheck>
        <OptionDescriptionText>
          <FormattedMessage
            id="cart.options.processDelay"
            values={{ pickupLeadTime }}
          />
        </OptionDescriptionText>
      </OptionDescriptionItem>
      <OptionDescriptionItem>
        <OptionDescriptionCheck>
          <CheckIcon />
        </OptionDescriptionCheck>
        <OptionDescriptionText>
          {priceIsZero
            ? <FormattedMessage id="cart.options.deliveryCostFree" values={{ price }} />
            : <FormattedMessage id="cart.options.deliveryCost" values={{ price }} />
          }
        </OptionDescriptionText>
      </OptionDescriptionItem>
    </OptionDescriptionView>
  );
};

export default OptionDescription;
