import React, { FC, ReactNode, CSSProperties } from 'react';

import { BUTTON_TYPE } from './style';
import { Container, Left, Loader, Right, Text } from './style';
import Spinner from '../../images/spinner.svg'

export { BUTTON_TYPE };

interface Props {
  children: string;
  loading?: boolean;
  type?: BUTTON_TYPE;
  left?: ReactNode;
  right?: ReactNode;
  style?: CSSProperties;
  onClick: () => void;
}

const ButtonText: FC<Props> = ({
  children,
  loading = false,
  type = BUTTON_TYPE.PRIMARY,
  left,
  right,
  style,
  ...props
}) => (
  <Container style={style} type={type} {...props}>
    {loading ? (
      <Loader>
        <Spinner />
      </Loader>
    ) : (
      <>
        {left && <Left>{left}</Left>}
        <Text type={type}>{children}</Text>
        {right && <Right>{right}</Right>}
      </>
    )}
  </Container>
);

export default ButtonText;
