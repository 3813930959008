import React from "react";
import {
  CartMenu,
  DesktopMenuLinkSmall,
  DesktopMenuTextSmall,
} from "../desktop-menu/style";
import ArrowLeftCircle from "../../images/arrow-left.svg";
import { useIntl } from "react-intl";

export const BackBtnIcon: React.FC = () => {
  const intl = useIntl();
  return (
    <DesktopMenuLinkSmall href="#!" onClick={(): void => window.history.back()}>
      <CartMenu
        title={intl.formatMessage({
          id: "profile.home.title",
        })}
      >
        <DesktopMenuTextSmall>
          <ArrowLeftCircle />
        </DesktopMenuTextSmall>
      </CartMenu>
    </DesktopMenuLinkSmall>
  );
};
