import React, { useCallback, useMemo, ReactElement, FC } from "react";
import { useDispatch, useSelector } from "react-redux";

import twoDecimal from "../../utils/twoDecimals";
import { AppState } from "../../store";
import { setCurrentOption } from "../../store/shopping-cart/actions";
import { ShoppingCartActionTypes } from "../../store/shopping-cart/types";
import useOptions from "./use-options";
import { Option } from "../../types/option";
import Spinner from "../../images/spinner.svg";
import OptionDescription from "./option-description";
import {
  FreeDelivery,
  OptionButton,
  OptionsButtons,
  OptionText,
  SpinnerContainer,
} from "./style/options";

const Options: FC = () => {
  const dispatch = useDispatch();
  const currentOption = useSelector<AppState, Option | undefined>(
    state => state.shoppingCart.currentOption
  );
  const options = useSelector<AppState, Option[] | undefined>(
    state => state.shoppingCart.options
  );

  const { loading } = useOptions();

  const setClass = useCallback(
    (option: Option) => (): ShoppingCartActionTypes =>
      dispatch(setCurrentOption(option)),
    [dispatch]
  );

  const optionsToRender = useMemo<ReactElement[] | undefined>(() => {
    if (!loading && currentOption && options) {
      return options.map((option: Option, index: number) => {
        const { id, name } = option;
        let buttonOrder = "middle";
        if (index === 0) {
          buttonOrder = "first";
        } else if (index === options.length - 1) {
          buttonOrder = "last";
        }
        return (
          <OptionButton
            key={id}
            buttonOrder={buttonOrder}
            selected={id === currentOption.id}
            onClick={setClass(option)}
          >
            <OptionText selected={id === currentOption.id}>{name}</OptionText>
            { index === 0 && id === currentOption.id
              ? <FreeDelivery>Livraison offerte</FreeDelivery>
              : null
            }
          </OptionButton>
        );
      });
    }

    return undefined;
  }, [loading, currentOption, options, setClass]);

  const description = useMemo(() => {
    if (!loading && currentOption && options) {
      return (
        <OptionDescription
          price={twoDecimal(currentOption.price / 100)}
          pickupLeadTime={currentOption.pickupLeadTime}
          timeslotDuration={currentOption.timeslotDuration}
        />
      );
    }
  }, [loading, currentOption, options]);

  if (loading || !options || !currentOption) {
    return (
      <SpinnerContainer>
        <Spinner />
      </SpinnerContainer>
    );
  }

  return (
    <>
      <OptionsButtons>{optionsToRender}</OptionsButtons>
      {description}
    </>
  );
};

export default Options;
