import styled from "styled-components";

interface OptionTextProps {
  selected: boolean;
  buttonOrder?: string;
}

export const OptionButton = styled.div<OptionTextProps>`
  align-items: center;
  background-color: ${({ selected, theme: { colors } }): string => selected ? colors.secondary : colors.white};
  border-bottom-left-radius: ${({ buttonOrder }): string => buttonOrder === "first" ? "10px" : "0"};
  border-bottom-right-radius: ${({ buttonOrder }): string => buttonOrder === "last" ? "10px" : "0"};
  border-top-left-radius: ${({ buttonOrder }): string => buttonOrder === "first" ? "10px" : "0"};
  border-top-right-radius: ${({ buttonOrder }): string => buttonOrder === "last" ? "10px" : "0"};
  cursor: pointer;
  display: flex;
  height: 48px;
  justify-content: center;
  position: relative;
  width: 33%;
`;

export const OptionsButtons = styled.div`
  display: flex;
  flex-direction: row;
  margin: 10px 0;
`;

export const OptionText = styled.span<OptionTextProps>`
  color: ${({ selected, theme: { colors } }): string => selected ? colors.white : "#000000"};
  font-family: ${({ theme: { fonts } }): string => fonts.main };
`;

export const SpinnerContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
`;

export const FreeDelivery = styled.span`
  background-color: white;
  border-radius: 30px;
  font-size: 10px;
  font-weight: bold;
  line-height: 1em;
  max-width: 70px;
  padding: 2px 0;
  position: absolute;
  right: 2px;
  text-align: center;
  top: 0;
  transform: translateY(-50%);
`;
