import styled from 'styled-components';

import ButtonText, { BUTTON_TYPE } from '../../../ui/button-text';
import OInput from '../../../ui/input';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  height: 48px;
  margin-bottom: 14px;
`;

export const Button = styled(ButtonText).attrs({ type: BUTTON_TYPE.SECONDARY })`
  padding-left: 0;
`;

export const Input = styled(OInput)`
  flex: 1;
`;

export const DiscountContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const DiscountText = styled.span`
  font-family: ${({ theme: { fonts } }): string => fonts.main};
  font-weight: bold;
  font-size: 14px;
  color: ${({ theme: { colors } }): string => colors.primary};
`;

export const RemoveDiscountButton = styled.div`
  margin-left: 10px;
  padding: 10px;
  cursor: pointer;
`;

export const InputContainer = styled.div`
  background-color: ${({ theme: { colors } }): string => colors.white};
  border: 1px solid ${({ theme: { colors } }): string => colors.secondary};
  border-radius: 20px;
  display: flex;
  padding-right: 10px;
  width: 100%;

  & > div {
    border: none;
  }
`;

export const InputValidateText = styled.div`
  width: 30%;
  height: 35px;
  line-height: 30px;
  margin-left: 30px;
  margin-top: 5px;
  font-family: ${({ theme: { fonts } }): string => fonts.main};
  font-weight: bold;
  font-size: 14px;
  color: ${({ theme: { colors } }): string => colors.secondary};
  cursor: pointer;
`;
