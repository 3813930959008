import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

interface Props {
  isFirstChild?: boolean;
}

export const SummaryLineContainer = styled.div<Props>`
  margin-top: ${({ isFirstChild }): number => (!isFirstChild ? 14 : 0)}px;
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const MinimumPrice = styled.span`
  font-family: ${({ theme: { fonts } }): string => fonts.main};
  font-size: 12px;
  color: ${({ theme: { colors } }): string => colors.lightGrey};
  margin-top: 10px;
  text-align: center;
`;

export const InputDark = styled.span`
  font-family: ${({ theme: { fonts } }): string => fonts.main};
  font-size: 14px;
  color: ${({ theme: { colors } }): string => colors.primary};
`;

export const H3Dark = styled.span`
  font-family: ${({ theme: { fonts } }): string => fonts.main};
  font-weight: bold;
  font-size: 14px;
  color: ${({ theme: { colors } }): string => colors.primary};
`;
