import React, { useCallback, useState, useMemo, FC } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useIntl, FormattedMessage } from 'react-intl'

import { Button, Container, Input, DiscountContainer, DiscountText, RemoveDiscountButton, InputContainer, InputValidateText } from './style';
import PromoIcon from '../../images/promo.svg';
import TrashIcon from '../../images/trash-red.svg';
import Spinner from '../../images/spinner.svg';

import { useInput } from '../../ui/input';
import { useToast, TOAST_TYPE } from '../../ui/toast';
import useThunkDispatch from '../../hooks/use-thunk-dispatch';

import { sendDiscountCode, removeDiscountCode } from '../../store/shopping-cart/actions';
import { DiscountCode as DiscountCodeType } from '../../types/discount';
import { AppState } from '../../store';
import twoDecimals from '../../utils/twoDecimals';

const DiscountCode: FC = () => {
  const [inputShown, setInputShown] = useState(false);
  const [loading, setLoading] = useState(false);
  const intl = useIntl();
  const thunkDispatch = useThunkDispatch();
  const dispatch = useDispatch();
  const { showToast } = useToast();

  const discountCode = useSelector<AppState, DiscountCodeType | undefined>(state => state.shoppingCart.discountCode);

  const showInput = useCallback(() => {
    setInputShown(true);
  }, [setInputShown]);

  const saveOnBlur = useCallback(
    async ({ value }) => {
      setLoading(true);
      const response = await thunkDispatch(sendDiscountCode(value));

      if (response.ok) {
        showToast(intl.formatMessage({ id: "cart.discount.discountCodeApplied" }), { type: TOAST_TYPE.SUCCESS });
      } else {
        showToast(response.error ? response.error.message : 'Error', { type: TOAST_TYPE.ERROR });
      }

      setLoading(false);
    },
    [intl, thunkDispatch, showToast, setLoading],
  );

  const handleRemoveCode = useCallback(() => {
    dispatch(removeDiscountCode());
  }, [dispatch]);

  const discount = useMemo<undefined | string>(() => {
    if (discountCode && discountCode.discount.type === 'AMOUNT') {
      return `${discountCode.code}    -${twoDecimals(discountCode.discount.value / 100)}€`;
    } else if (discountCode && discountCode.discount.type === 'PERCENT') {
      return `${discountCode.code}    -${discountCode.discount.value}%`;
    }
    return undefined;
  }, [discountCode]);

  const inputProps = useInput({ saveOnBlur });

  return (
    <Container>
      {!inputShown && !discount && (
        <Button onClick={showInput} left={<PromoIcon />}>
          {intl.formatMessage({ id: "cart.discount.enterDiscountCode" }) }
        </Button>
      )}
      {inputShown && !discount && (
        <InputContainer>
          <Input {...inputProps} placeholder={intl.formatMessage({ id: "cart.discount.enterDiscountCode" })} />
          <InputValidateText>{loading ? <Spinner /> : <FormattedMessage id="cart.shippingTime.validate" />}</InputValidateText>
        </InputContainer>
      )}
      {discount && (
        <DiscountContainer>
          <DiscountText>{discount}</DiscountText>
          <RemoveDiscountButton onClick={handleRemoveCode}>
            <TrashIcon />
          </RemoveDiscountButton>
        </DiscountContainer>
      )}
    </Container>
  );
};

export default DiscountCode;
