import React, { FC } from "react";
import { useSelector } from "react-redux";
import { FormattedMessage } from "react-intl";

import {
  Container,
  SummaryLineContainer,
  MinimumPrice,
  InputDark,
  H3Dark,
} from "./style/order-summary";

import { AppState } from "../../store";
import { Option } from "../../types/option";

import twoDecimals from "../../utils/twoDecimals";

const OrderSummary: FC = () => {
  const totalPrice = useSelector<AppState, number>(
    (state): number => state.shoppingCart.totalPrice
  );
  const currentOption = useSelector<AppState, Option | undefined>(
    (state): Option | undefined => state.shoppingCart.currentOption
  );

  return (
    <Container>
      <SummaryLineContainer isFirstChild>
        <InputDark>
          <FormattedMessage id="shippingCost" />
        </InputDark>
        <InputDark>
          {currentOption && currentOption.price > 0 ? (
            `${twoDecimals(currentOption.price / 100)} €`
          ) : (
            <FormattedMessage id="given" />
          )}
        </InputDark>
      </SummaryLineContainer>
      <SummaryLineContainer>
        <InputDark>
          <FormattedMessage id="total" />
        </InputDark>
        <H3Dark>{twoDecimals(totalPrice / 100)} €</H3Dark>
      </SummaryLineContainer>
      <MinimumPrice>
        <FormattedMessage id="minimumOrderPrice" />
      </MinimumPrice>
    </Container>
  );
};

export default OrderSummary;
