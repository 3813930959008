import React, { FC } from "react";
import { FormattedMessage } from "react-intl";
import "dayjs/locale/en";
import "dayjs/locale/fr";
import dayjs from "dayjs";

import { DateText, TimeBlock, TimeText, TimeTitle, TimeView } from "./style";
import usePickupDropOffRangePicker from "./use-pickup-drop-off-range-picker";
import Spinner from "../../images/spinner.svg";
import PickupDropOffRangePickerModal from "./modal";
import { Order } from '../../types/order';
import userLanguage from "../hooks/useLanguage";

interface Props {
  reschedule?: boolean;
  order?: Order;
}

const PickupDropOffRangePicker: FC<Props> = ({ reschedule, order }) => {
  const {
    onPickupPress,
    onDropOffPress,
    loading,
    loaded,
    pickupRange,
    dropOffRange,
    ...pickupDropOffRangePickerModalProps
  } = usePickupDropOffRangePicker(reschedule, order);

  const locale = userLanguage();

  if (loading) {
    return <Spinner />;
  }

  return (
    <>
      <TimeView>
        {loaded ? (
          <>
            <TimeBlock onClick={onPickupPress}>
              <TimeTitle>
                {pickupRange ? (
                  <FormattedMessage id="cart.shippingTime.pickup" />
                ) : (
                  <FormattedMessage id="cart.shippingTime.selectPickupRange" />
                )}
              </TimeTitle>
              {pickupRange && (
                <>
                  <TimeText>{`${dayjs(pickupRange.startDate).format(
                    "HH:mm"
                  )} - ${dayjs(pickupRange.endDate).format(
                    "HH:mm"
                  )}`}</TimeText>
                  <DateText>
                    {dayjs(pickupRange.startDate)
                      .locale(locale)
                      .format("dddd D MMMM")}
                  </DateText>
                </>
              )}
            </TimeBlock>
            <TimeBlock onClick={onDropOffPress} disabled={!pickupRange}>
              <TimeTitle>
                {dropOffRange ? (
                  <FormattedMessage id="cart.shippingTime.dropoff" />
                ) : (
                  <FormattedMessage id="cart.shippingTime.selectDropOffRange" />
                )}
              </TimeTitle>
              {dropOffRange && (
                <>
                  <TimeText>{`${dayjs(dropOffRange.startDate).format(
                    "HH:mm"
                  )} - ${dayjs(dropOffRange.endDate).format(
                    "HH:mm"
                  )}`}</TimeText>
                  <DateText>
                    {dayjs(dropOffRange.startDate)
                      .locale(locale)
                      .format("dddd D MMMM")}
                  </DateText>
                </>
              )}
            </TimeBlock>
          </>
        ) : (
          <TimeTitle>
            <FormattedMessage id="cart.shippingTime.chooseAddressBeforeTime" />
          </TimeTitle>
        )}
      </TimeView>
      {
        <PickupDropOffRangePickerModal
          {...pickupDropOffRangePickerModalProps}
        />
      }
    </>
  );
};

export default PickupDropOffRangePicker;
